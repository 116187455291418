
import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <body >
      <Header siteTitle="SMOOTHSLERP" />
      <div className="main-content">
        <main >{children}</main>
      </div>
        <footer>
            <i>
              Follow me on <a href="https://www.github.com/smoothslerp"><u>Github</u></a> <br/><br/>
              New shaders / gamedev tutorials every once in a while <br/><br/>
              Questions? Comments? You can reach me at <address>admin@smoothslerp.com</address> 
            </i>
        </footer>
    </body>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
